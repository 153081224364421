import React from "react";
import "./Circle.css";

const Circle = (props) => {
  return (
    <div className="circlee">
    <div>
    <img src={props.imgg} alt="no" className="imag"/>
    </div>

      <div className="line">
         <div className="dot"></div>
      </div>
    </div>
  );
};

export default Circle;
