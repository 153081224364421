import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Import your components
import Header from '../components/Header/Header';
import Section1 from '../components/WhatIsCodeto/Section1';
import Section2 from '../components/Courses/Section2';
import Section3 from '../components/WhyCodeto/Section3';
import Section4 from '../components/CodetoCulture/Section4';
import Section5 from '../components/AboutUs/Section5';
import AccordionSection from '../components/FAQ/Accordion';
import Footer from '../components/Footer/Footer';
import ChatbotLogo from '../components/Chatbot/ChatbotLogo';

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000, // Adjust the animation duration as needed
      once: false, // Only animate elements once
    });
  }, []);

  return (
    <div >
      <Header data-aos="fade-down" /> 
      <div data-aos="fade-up"><Section1  /></div> 
     <div data-aos="fade-up"><Section2  /></div>  
    <div data-aos="fade-up" ><Section3 /> </div>  
     <div data-aos="fade-up" ><Section4 /></div>  
     <div data-aos="fade-up"><Section5  /></div>  
     <div data-aos="fade-up" ><AccordionSection  /></div>  
      <Footer  /> 
      <ChatbotLogo/> 
    </div>
  );
};

export default Home;
