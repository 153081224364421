import React, {useState} from "react";
import Navbar2 from "../../CoursesMenu/Navbar2/Navbar2.js";
import "./Header2.css";
import EnquiryModal from '../../EnquiryForm/Modal'
import image from "../../assets/courses_menu/header2_image.png";

const Home = () => {


  //modal enquiry
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="header2">
      <Navbar2 />
      
      <div className="whole_content">
      <div className="left">
      <div className="page2-title">
      <p>
          FIND YOUR PREFERRED <br/> COURSES & IMPROVE 
           YOUR SKILLS
        </p>
      </div>
      <div>
        <button className="demo-btn" onClick={openModal}>Demo Class</button>
      </div>
      </div>

      <div className="right">
        <img className="img" src={image} alt="codeto" />
      </div>
      </div>
      <EnquiryModal isOpen={modalIsOpen} onClose={closeModal} />
       
    </div>
  );
};

export default Home;
