import React from "react";
import ChatBot from "react-simple-chatbot";
import "./style.css";
import { ThemeProvider } from "styled-components";
import chatbotLogo from "../../assets/Chatbot.png";

const CustomheaderComponent = () => {
  return (
    <div
      style={{
        background: "linear-gradient(282deg, #040FE1 0%, #8E4BF6 77.60%)",
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
      }}
      className="py-3 fs-6"
    >
      <div style={{ width: "3rem", height: "3rem" }}>
        <img src={chatbotLogo} alt="Chatbot Logo" width="100%" />
      </div>
      <div
        style={{
          background: "#fff",
          borderRadius: "50px",
          color: "#000",
          fontWeight: "bold",
        }}
        className="py-1 px-3 fs-6"
      >
        <span style={{ fontSize: ".8rem" }}> CODETO Assistant</span>
      </div>
    </div>
  );
};

const CustomChatbot = ({ showToggleableComponent }) => {
  
  const steps = [
    {
      id: "0",
      message: "Hello, I'm here to assist you.",
      trigger: "1",
    },
    {
      id: "1",
      message: " May I know your name?",
      trigger: "name",
    },
    
    {
      id: "name",
      user: true,
      trigger: "welcome",
    },

    {
      id: "welcome",
      message:
        "Hi {previousValue}! Welcome to CodeTo Learning program! How can I assist you today?",
      trigger: "options",
    },
    {
      id: "options",
      options: [
        {
          value: "courses",
          label: "Explore Courses",
          trigger: "explore-courses",
        },
        {
          value: "admission",
          label: "Admission Information",
          trigger: "admission",
        },
        { value: "contact", label: "Contact Us", trigger: "contactus" },
      ],
    },
    {
      id: "explore-courses",
      message:
        "Great! We offer a variety of courses to choose from. What area are you interested in?",
      trigger: "course-categories",
    },
    {
      id: "course-categories",
      options: [
        { value: "designing", label: "Designing", trigger: "designing" },
        { value: "technical", label: "Technical", trigger: "technical" },
      ],
    },
   
    {
      id: "designing",
      message: "Here are some popular designing courses we offer:",
      trigger: "designing-list",
    },

    {
      id: "technical",
      message: "Here are some popular technical courses we offer:",
      trigger: "technical-list",
    },
    {
      id: "designing-list",
      options: [
        { value: "3dmodel", label: "3D modelling", trigger: "contact" },
        { value: "3danimation", label: "3D animation", trigger: "contact" },
        { value: "ui/ux", label: "UI/UX", trigger: "contact" },
      ],
    },
    {
      id: "technical-list",
      options: [
        { value: "Python", label: "Python", trigger: "contact" },
        { value: "Java", label: "Java", trigger: "contact" },
        { value: "Node.js", label: "Node.js", trigger: "contact" },
        { value: "React.js", label: "React.js", trigger: "contact" },
        { value: "C#", label: "C#", trigger: "contact" },
        { value: "C/C++", label: "c/c++", trigger: "contact" },
        { value: "React Native", label: "React Native", trigger: "contact" },
        { value: "more", label: "More..", trigger: "more" },
      ],
    },
    {
      id: "more",
      options: [
        { value: "Python", label: "Python", trigger: "contact" },
        { value: "Java", label: "Java", trigger: "contact" },
        { value: "Node.js", label: "Node.js", trigger: "contact" },
        { value: "React.js", label: "React.js", trigger: "contact" },
        { value: "C#", label: "C#", trigger: "contact" },
        { value: "C/C++", label: "c/c++", trigger: "contact" },
        { value: "React Native", label: "React Native", trigger: "contact" },
        { value: "Blockchain", label: "Blockchain", trigger: "contact" },
        { value: "Unity", label: "Unity", trigger: "contact" },
        { value: "Unreal", label: "Unreal", trigger: "contact" },
        {
          value: "Web Development",
          label: "Web Development",
          trigger: "contact",
        },
        {
          value: "Machine Learning",
          label: "Machine learning",
          trigger: "contact",
        },
        {
          value: "Full Stack Development",
          label: "Full stack development",
          trigger: "contact",
        },
      ],
    },
    {
      id: "contact",
      message:
        "Great!, you are intrested in {previousValue}. Wants to contact now?",
      options: [
        { value: "Yes", label: "Yes", trigger: "admission-process" },
        { value: "No", label: "No", trigger: "end-message" },
      ],
    },

    {
      id: "admission",
      message:
        "Interested in joining CodeTo? We have a straightforward admission process. Let me guide you through it.",
      trigger: "admission-process",
    },

    {
      id: "admission-process",
      message: "You have to fill enquiry form",
      trigger: "contactus",
    },
    {
      id: "contact",
      message: "Great!, you are interested in {previousValue}. Wants to contact now?",
      trigger: "contact-options",
    },

    {
      id: "contactus",
      message: " Wants to contact now?",
      trigger: "contact-options",
    
    },
    {
      id: "contact-options",
      message: "Contact us: 9910655805 ",
      trigger:  "end-message"
    },

  
    {
      id: "end-message",
      message:
        "Any other query.?",
        trigger: "end-message2",
    },
    {
      id: "end-message2",
      
        options: [
          { value: "Yes", label: "Yes", trigger: "options" },
          { value: "No", label: "No", trigger: "chatclose" },
        ],
    },
    {
      id: "chatclose",
        message:
          "Thank you for chatting with us.",
    },
  ];

  const theme = {
    background: "#fff",
    fontFamily: "Poppins",
    fontWeight: "normal",
    headerBgColor: "linear-gradient(282deg, #040FE1 0%, #8E4BF6 77.60%)",
    headerFontColor: "#fff",
    headerFontSize: "15px",
    headerBorderRadius: "50px",
    botBubbleColor: "#6842D3",
    botFontColor: "#fff",
    userBubbleColor: "#1288BE",
    userFontColor: "fff",
  };

  return (
    <div
      className="chatbot-container"
      style={{ display: showToggleableComponent ? "block" : "none" }}
    >
      <ThemeProvider theme={theme}>
        <ChatBot
          steps={steps}
          headerComponent={<CustomheaderComponent />}
          style={{ height: "auto" }}
          enableSmoothScroll={true}
          botAvatar={chatbotLogo}
        />
      </ThemeProvider>
    </div>
  );
};

export default CustomChatbot;
     