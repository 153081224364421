import React, {useState} from "react";
import Accordion from "react-bootstrap/Accordion";
import "./Accordian.css";

const AccordionSection = () => {

  const [activeKey, setActiveKey] = useState("1");
  const [more, setMore] = useState(false);

  const toggleMenu = () => {
    setMore((prevState) => !prevState);
  };

  const handleAccordionChange = (eventKey) => {
    setActiveKey(eventKey);
  };


  return (
    <div className="accordion">
      <div className="frequently-question-title">
        <p>FREQUENTLY ASKED QUESTIONS</p>
      </div>
      <div className="accordion-box">
      <Accordion  activeKey={activeKey} onSelect={handleAccordionChange}>
          <Accordion.Item eventKey="1">
            <Accordion.Header>1. Is this an online program?</Accordion.Header>
            <Accordion.Body>It is online/offline both Mode.</Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>2. How does the program work?</Accordion.Header>
            <Accordion.Body>
              CODETO is the project-based learning platform for developers.
              Everything you learn in this program will revolve around building
              work-like professional projects in an actual developer
              environment. Learning through this program is quite literally
              similar to learning on-the-job or learning through actual work
              experience. While this may sound challenging, this is the best way
              to learn a new technology skill, and our mentors will be there to
              guide you through your journey. This is your chance to go beyond
              this courses and upskill your career with confidence.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              3. Will I get a certificate at the end of the program?
            </Accordion.Header>
            <Accordion.Body>
              Yes, you will receive a Codeto program certificate post Completion of the Courses.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>
              4. Which companies will I be referred to on completing the
              "CODETO" program?
            </Accordion.Header>
            <Accordion.Body>
              Our career team helps you find the right career opportunity
              through referrals, and identifying opportunities that you can
              apply to. Job opportunities span across tech MNCs and exciting
              product-based tech startups.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              5. What if I decide to leave in the middle of the program?
            </Accordion.Header>
            <Accordion.Body>
              There will be no refund if you do not complete the program and
              leave in between.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion> 
          

        {/* after click on show more */}
        {more && (
          <Accordion activeKey={activeKey} onSelect={handleAccordionChange}>
          <Accordion.Item eventKey="6">
            <Accordion.Header>6. How long is the course and how often will classes be held?</Accordion.Header>
            <Accordion.Body>The course will be approximately of 2 to 3 months.</Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="7">
            <Accordion.Header>7. Will this course be taught by industry professionals?</Accordion.Header>
            <Accordion.Body>
            Yes, it will be taught by industry professionals.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="8">
            <Accordion.Header>
           8. Will this course be suitable for beginners?
            </Accordion.Header>
            <Accordion.Body>
            Yes, suitable for all levels, we provide a variety of courses that are beginner friendly such as react for beginners, adobe animation, java course, ui/ux course, blockchain programming certification courses and many more.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="9">
            <Accordion.Header>
            9. Will the course cover both 2D and 3D game development?
            </Accordion.Header>
            <Accordion.Body>
            Yes, we will cover both the topics.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="10">
            <Accordion.Header>
            10. Do you need to be a gamer for opting this course?
            </Accordion.Header>
            <Accordion.Body>
            No, we will teach you everything
            </Accordion.Body>
          </Accordion.Item>
         </Accordion>
          )}
      </div>
      <div className="show-more ">
      <button className="nav-button" onClick={toggleMenu}>
          {more ? "SHOW LESS" : "SHOW MORE"}{" "} {more ? <i class="fa-solid fa-circle-chevron-up"></i>: <i class="fa-solid fa-circle-chevron-down"></i>}
        </button>
      </div>
    </div>
  );
};

export default AccordionSection;