import React,{useState} from "react";
import "./Interested.css";

import EnquiryModal from '../../EnquiryForm/Modal'

import buy1 from "../../assets/courses_menu/buy1.png";
import buy2 from "../../assets/courses_menu/buy2.png";

const Interested = ({getSingleCourse, singleTechCourse}) => {

//modal enquiry
const [modalIsOpen, setModalIsOpen] = useState(false);

const openModal = () => {
  setModalIsOpen(true);
};

const closeModal = () => {
  setModalIsOpen(false);
};


  return (
    <div className="interested">
      <div className="interested-title">
        <span>
          INTERESTED IN OUR OTHER
          <br /> COURSES TOO ?
        </span>
      </div>

      <div className="buy">
        <div className="buy-img" onClick={openModal}>
        <img src={buy1} alt="offer" />
       </div>
       

       <div className="buy-img" onClick={openModal}>
          <img src={buy2} alt="offer" />
       </div>
      </div>
      
      <div className="offer">   
         <div className="offer_pill">
               <img src={singleTechCourse[0]?.pills} from alt="offer" />
           
         </div>

        <div className="offer-text">
          <p className="text1">1+2 offer</p>
          <p className="text2">
            Get internship & job preparation <br />
            training free on purchase of {singleTechCourse[0]?.offer} <br />
            course and get welcome kit
          </p>
        </div>
      </div>
      <EnquiryModal isOpen={modalIsOpen} onClose={closeModal} />
    </div>
  );
};

export default Interested;



