import { Carousel } from "react-bootstrap";
import React from "react";
import "./Carousel.css";
import Stars from "../CoursesMenu/Rating/Stars";
import arya from "../assets/Rating/ratingimg2.png";
import gauravup from "../assets/Rating/gauravup.jpg";
import rahul from "../assets/Rating/rahul.jpeg";
// import gauravrawal from "../assets/Rating/gauravrawal.png";
import ganga from "../assets/Rating/ganga.jpeg";
import hari from "../assets/Rating/hari.png";
import vijay from "../assets/Rating/vijay.png";
import twinkle from "../assets/Rating/twinkle.jpg";
import shivam from "../assets/Rating/shivam.jpg";

const RatingCarousel = () => {
  return (
    <>
      <div className="rating pt-2">
        <div className="rating-title mt-3">
          <h1>WHAT’S CODETO STUDENTS ABOUT TO SAY</h1>
        </div>
      </div>
      <Carousel
        data-bs-theme="dark"
        style={{ width: "90%", height: "", margin: "10rem  auto" }}
      >

<Carousel.Item interval={1000}>
          <div className="items">
            <div className="student-image">
              <img src={rahul} style={{ borderRadius: "50%" }} alt="avatar" />
            </div>
            <div className="content">
              <p>
                I look forward to doing more courses with you guys in the future
                and have been recommending you to everyone . I would like to
                thank you for the helpful guidance provided by the tutorial
                department.
              </p>
              <h5 style={{ color: "" }}> - Rahul Sharma</h5>
              <Stars />
            </div>
          </div>
        </Carousel.Item>


        <Carousel.Item interval={1000}>
          <div className="items">
            <div className="student-image">
              <img src={shivam} style={{ borderRadius: "50%" }} alt="avatar" />
            </div>
            <div className="content">
              <p>
                Thanks codeto to enhance my skills and learn so many this during my training. Also experience, how the office culture is.
              </p>
              <h5 style={{ color: "" }}> - Shivam</h5>
              <Stars />
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item interval={1000}>
          <div className="items">
            <div className="student-image">
              <img src={arya} alt="avatar" />
            </div>
            <div className="content">
              <p>
                When student is wants the trainer will appear and codeto is the
                best example for solving students problems.
              </p>
              <h5 style={{ color: "" }}> - Arya Singh</h5>
              <Stars />
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <div className="items">
            <div className="student-image">
              <img src={twinkle} style={{ borderRadius: "50%" }} alt="avatar" />
            </div>
            <div className="content">
              <p>
                {" "}
                I feel glad to join this organization. Their work environment is
                very good and employees are very supportive.
              </p>
              <h5 style={{ color: "" }}> - Twinkle</h5>
              <Stars />
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <div className="items">
            <div className="student-image">
              <img src={ganga} style={{ borderRadius: "50%" }} alt="avatar" />
            </div>
            <div className="content">
              <p>
                I am thrilled to share my experience as a student at CodeTo, and
                I can confidently say that it has been an incredible journey of
                growth, learning, and personal development I wholeheartedly
                recommend CodeTo . They have earned my trust and I wouldn't
                hesitate to engage with them again.
              </p>
              <h5 style={{ color: "" }}> - Ganga Singh</h5>
              <Stars />
            </div>
          </div>
        </Carousel.Item>
       
        <Carousel.Item interval={1000}>
          <div className="items">
            <div className="student-image">
              <img src={vijay} style={{ borderRadius: "50%" }} alt="avatar" />
            </div>
            <div className="content">
              <p>
                I am extremely glad to share my experience with Codeto. My
                journey here has been nothing short of incredible. The range of
                knowledge I've acquired during my time at the institute has
                exceeded my expectations.
              </p>
              <h5 style={{ color: "" }}> - Vijay Singh</h5>
              <Stars />
            </div>
          </div>
        </Carousel.Item>
        {/* <Carousel.Item interval={1000}>
          <div className="items">
            <div className="student-image">
              <img src={gauravrawal} alt="avatar" />
            </div>
            <div className="content">
              <p>
                Having a great experience in learning new technologies and
                enhancing skills with Codeto. Also experience office work
                culture.
              </p>
              <h5 style={{ color: "" }}> - Gaurav Rawal</h5>
              <Stars />
            </div>
          </div>
        </Carousel.Item> */}
        <Carousel.Item interval={1000}>
          <div className="items">
            <div className="student-image">
              <img src={hari} style={{ borderRadius: "50%" }} alt="avatar" />
            </div>
            <div className="content">
              <p>
                {" "}
                Good working environment, staff are good and have helping
                nature. Great place to start career.
              </p>
              <h5 style={{ color: "" }}> - Hari Om</h5>
              <Stars />
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <div className="items">
            <div className="student-image">
              <img
                src={gauravup}
                style={{ borderRadius: "50%" }}
                alt="avatar"
              />
            </div>
            <div className="content">
              <p>
                Codeto provide us trainer who support and encourage us in
                learning. It was truly exceptional, and it positively impacted
                my experience.
              </p>
              <h5 style={{ color: "" }}> - Gaurav Upadhyay</h5>
              <Stars />
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default RatingCarousel;
