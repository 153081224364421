import React from "react";
import "./privacypolicy.css";
import Nav from "react-bootstrap/Nav";
import navvlogo from "../../assets/CodeTo Logo.png";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const homepage = () => {
    navigate("/");
  };

  return (
    <>
      <Helmet>
        <title>Codeto - PrivacyPolicy </title>
        <meta name="description" content="PrivacyPolicy" />
      </Helmet>
      <Nav style={{ margin: "1.5rem" }}>
        <Nav.Item>
          <img src={navvlogo} onClick={homepage} id="privacy" alt="noimg" />
        </Nav.Item>
      </Nav>
      <div className="privacy">
        <h1 className="privacy-title ">Privacy Policy</h1>
        <p>
        <br></br>
          <h4>DISCLAIMER</h4><br/> The information on this website <a href="https://www.codeto.codes/" style={{textDecoration: "none"}}>(Codeto.codes)</a>  is for gives
          information to students only. All content on the website is offered in
          good faith. CODETO exercises sufficient caution for ensuring that
          anything found on the website is current, accurate, and correct.
          Students can conduct their own research before relying on the same.<br/><br/>
          <h4> Personal Data: </h4><br/> At the time we request for any personal information of
          yours , we will clarify the reasons for doing so. In case you get in
          touch or contact us, we might ask you for more information, like
          contact details, name, phone number, email address or any other
          details.
            <br/><br/>
            <h4>We employ the information in a number of ways:</h4>      


<br/>Recognise and examine our website‘s usage by you
Maintain and provide information to our website
For developing new services and features
Improvisation and personalisation of website
          <br/> <br/>   <h4>How we use your
          data: </h4>      
<br/>1.Sending you emails<br/> 2.We may contact you for website updates,
          customer service, and other information, promotional and marketing
          purposes. <br/>Policy for Email, SMS, and Call:<br/><br/> When you fill out a form or
          register on our website, only we get your information. Additionally,
          you might be required to submit your name, email address, or phone
          number while completing a form on our website. We reserve the right to
          call, SMS, email, or use WhatsApp to communicate with you about our
          products and services in the event that you have provided your
          personal information and contact details—even if your phone number is
          set to DND.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
