import React, {useEffect} from 'react';
import './sidebar.css';
import { HashLink as Link } from "react-router-hash-link";

const Sidebar = ({ isOpen, onClose }) => {

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
    }
  }, [isOpen]);

  return (
    <div className={`sidebar ${isOpen ? 'open'  : ''}`}>
    <Link to="/"  onClick={onClose} >HOME</Link>
      <Link to="/#course"  onClick={onClose}>COURSES</Link>
    <Link to="/#culture"  onClick={onClose}>CULTURE</Link>
      <Link to="/#aboutus"  onClick={onClose}>ABOUT US</Link>
    </div>
  );
};

export default Sidebar;
