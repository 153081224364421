import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import "./header.css";
import logo from "../../assets/logo2.png";
import EnquiryModal from "../../EnquiryForm/Modal";
import { Helmet } from "react-helmet";
import bubble from '../../assets/bubble.png'

const Home = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Codeto - Home</title>
       
      </Helmet>
      <div className="header">
                             {/* ADD SECTION HOME */}
                             <div className="alert alert-success text-center font-800 banner-ad mt-4" role="alert">
 Cheapest price ever on courses, at just <span style={{color: 'rgb(228, 64, 166)', fontWeight: '700'}}>Rs.2999/</span>. <span style={{cursor: 'pointer'}} onClick={openModal}>Enroll now</span>
</div>
        <Navbar />
        <div>
          <p className="header-title">ABHIWAN TECHNOLOGY PRESENTS</p>
          
          <div>
            <img className="logo2" src={logo} alt="codeto" />
            
          </div>
          <div className="d-flex justify-content-center">
          <button className="registernow" onClick={openModal}>
            REGISTER NOW
          </button>
          
          </div>
          <div class="container bubble-container ">
    <div class="bubble">
        <span><img className="logo2" src={bubble} alt="codeto" style={{width: '100px'}}/></span>
    </div>
    <div class="bubble">
    <span><img className="logo2" src={bubble} alt="codeto" style={{width: '100px'}}/></span>
    </div>
    <div class="bubble">
    <span><img className="logo2" src={bubble} alt="codeto" style={{width: '100px'}}/></span>
    </div>
    <div class="bubble">
    <span><img className="logo2" src={bubble} alt="codeto" style={{width: '100px'}}/></span>
    </div>
    <div class="bubble">
    <span><img className="logo2" src={bubble} alt="codeto" style={{width: '100px'}}/></span>
    </div>
 </div>
        </div>
      </div>

      <div className="shawdow"></div>
      <EnquiryModal isOpen={modalIsOpen} onClose={closeModal} />
    </>
  );
};

export default Home;
