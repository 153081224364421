import React from "react";
import "./Section3.css";
import image from "../../assets/section3-whycodeto.png";

const Section3 = () => {
  return (
    <div className="section3">
      <div className="why-codeto">
        <h1>why codeto?</h1>
      </div>
      <div className="why-codeto-box-section">
        <div className="image-box">
          <img className="image" src={image} alt="codeto" />
        </div>
        <div className="why-codeto-box-1">
          <ul className="why-codeto-ans">
            <p className="title">
              Problems we faced while taking interviews of freshers:
            </p>
            <li>
              Students don’t know basic programming and their fundamentals.
            </li>
            <li>Lack of knowledge of technology. </li>
            <li>Students don’t know which field is better for them.</li>
            <li>
              While after selection they face problems in adapting the office
              culture.
            </li>
            <li>Students don’t know their field of interest. </li>
            <li>Lack of confidence.</li>
            <p className="sub-title">
              That’s why CodeTo is here, to help students with these problems
              and train them to face any type of interview .
            </p>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Section3;
