/* eslint-disable no-undef */
import { useEffect } from "react";
import "./fonts/fonts.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Coursespage from "./pages/coursespage";
import Home from "./pages/Home";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {createContext, useCallback, useState} from 'react';
import pic7 from "./assets/courses_logo/python.png";
import { courses } from "./coursesData";
import Admin from './AdminPanel/Login/Login';
import Show from "./AdminPanel/ShowData/Show";
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Terms from "./pages/Terms/Terms";
import Login from "./AdminPanel/Login/Login";
import { Toaster } from "react-hot-toast";
import Protected from "./Protected";
import NoPage from "./NoPage";


export const Context = createContext({});

function App() {

  const [singleTechCourse, setSingleTechCourse] = useState({
    id: 7,
    img: pic7 ,
    title: "Programming with python",
    subtitle:
      "Learn Python to master one of the most popular programming languages of the 21st century and most demanding language in industry.",
  });

  const getSingleCourse = useCallback((id) => {
     console.log(id);
    let course = courses.filter(course => parseInt(course.id) === parseInt(id));
    console.log(course)
    setSingleTechCourse(course);
 },[]);



//  const isUserAuthenticated = () => {
//   const userID = localStorage.getItem('userID');
//   return userID; 
// };
//  useEffect(()=>{
//   isUserAuthenticated()
//  },[])



useEffect(() => {

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag('js', new Date());
  gtag('config', 'G-59FT5ZXBDN'); 
}, []);



  return (
    <div className="App">
       <Context.Provider 
         value={{
         getSingleCourse : getSingleCourse,
         singleCourse : singleTechCourse
       }}
       >  
          <Router>
            <Routes>
              <Route path="/" element={<Home />}/>
              <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
              <Route path="/terms-conditions" element={<Terms/>} />
              <Route path="/Coursespage/:id" element={<Coursespage getSingleCourse={getSingleCourse}  singleTechCourse={singleTechCourse}/>} />

              <Route path="/show" element={<Protected><Show/></Protected>}/> 
              <Route path="/admin" element={<Login/>}/> 
              <Route path="/*" element={<NoPage/>}/> 
              
            </Routes>
          </Router> 
        
       </Context.Provider>

     
 <Toaster/>
    </div>
  );
}


export default App;
