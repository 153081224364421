import React from 'react'
import './Section4.css'
import culture_pic1 from '../../assets/culture_1.png'
import culture_pic2 from '../../assets/cultutre_pic2.png'
import culture_pic3 from '../../assets/culture_pic3.png'
import culture_pic4 from '../../assets/culture_pic4.png'
import culture_pic5 from '../../assets/culture_pic5.png'
import culture_pic6 from '../../assets/culture_pic6.png'

const Section4 = () => {
  return (
    <div className='codeto-culture' id='culture'>
    <h1 className='codeto-culture-title'>CODETO CULTURE</h1>
    <div className='grid'>

    <div className='grid-item'><img src={culture_pic1} alt='codeto-culture'/> </div>
    <div className='grid-item'><img src={culture_pic2} alt='codeto-culture'/>  </div>
    <div className='grid-item'> <img src={culture_pic3} alt='codeto-culture'/> </div>
    <div className='grid-item'> <img src={culture_pic4} alt='codeto-culture'/> </div>
    <div className='grid-item'> <img src={culture_pic5} alt='codeto-culture'/> </div>
    <div className='grid-item'> <img src={culture_pic6} alt='codeto-culture'/> </div>

    </div>
      
    </div>
  )
}

export default Section4
