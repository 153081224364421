import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import "./Modal.css";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import logoo from "../assets/CodeTo Logo.png";
import {formSubmitApi} from "../baseUrl";
import toast from "react-hot-toast";
import {Row} from "react-bootstrap";

const EnquiryModal = ({isOpen, onClose}) => {
    const [message, setMessage] = useState(null);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        purpose: "",
        courseSelected: "",
        location: "",
        education: "",
    });

    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => {
        if (isOpen) {
            setMessage(null);
            setValidationErrors({});
        }
    }, [isOpen]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateForm(formData);
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
        } else {
            try {
                const googleFormData = new URLSearchParams();
                googleFormData.append("entry.1110249806", formData.name);
                googleFormData.append("entry.1355320955", formData.email);
                googleFormData.append("entry.843178668", formData.phone);
                googleFormData.append("entry.55919461", formData.courseSelected);
                googleFormData.append("entry.369389419", formData.education);
                googleFormData.append("entry.245269597", formData.location);
                googleFormData.append("entry.732821509", formData.purpose);

                await axios.post(
                    "https://docs.google.com/forms/u/0/d/e/1FAIpQLScLAMaXE0GJf2IZYdTZkbierZC9owY1FG8668gQmZ-6uS86pw/formResponse",
                    googleFormData,
                    {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    }
                );

                const response = await axios.post(formSubmitApi, formData);

                const successMessage = response.data.message;
                setMessage(successMessage);
                toast.success("Submitted successfully");
                setTimeout(() => {
                    setFormData({
                        name: "",
                        email: "",
                        phone: "",
                        purpose: "",
                        courseSelected: "",
                        location: "",
                        education: "",
                    });
                }, 100);

                setTimeout(() => {
                    onClose();
                }, 500);
            } catch (error) {
                console.error("Error submitting enquiry:", error);
                toast.error("Server error");
            }
        }
    };

    const validateForm = (data) => {
        const errors = {};
        if (!data.name) {
            errors.name = "Name is required";
        } else if (!/\S/.test(data.name)) {
            errors.name = "Name must contain valid character";
        } else if (!/^[a-zA-Z\s]+$/.test(data.name)) {
            // Check if the name contains only letters and spaces
            errors.name = "Name must contain only letters";
        }

        if (!data.email) {
            errors.email = "Email is required";
        } else if (!isValidEmail(data.email)) {
            errors.email = "Invalid email format";
        }
        if (!data.phone) {
            errors.phone = "Phone number is required";
        } else if (!isValidPhoneNumber(data.phone)) {
            errors.phone = "Invalid phone number";
        }
        if (!data.purpose) {
            errors.purpose = "Purpose is required";
        }
        if (!data.courseSelected) {
            errors.courseSelected = "Course selection is required";
        }
        return errors;
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const isValidPhoneNumber = (phone) => {
        const phoneRegex = /^[0-9]{10}$/;
        return phoneRegex.test(phone);
    };

    return (
        <Modal show={isOpen} onHide={onClose} size="small" backdrop="static" className="dark-theme" centered>
            <Modal.Header closeButton closeVariant="white">
                <div className="d-flex justify-content-center w-100">
                    <img src={logoo} className="modallogo" alt="codeto" />
                </div>
            </Modal.Header>
            {/* {message && (
        <Alert key={"success"} variant={"success"}>
          <p>Form submitted</p>
        </Alert>
      )} */}
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Col className="mb-3">
                        <Form.Group as={Col} controlId="formGridName">
                            <Form.Label>
                                Name <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                maxLength="25"
                                placeholder="Enter name"
                                name="name"
                                onChange={handleChange}
                                value={formData.name}
                                isInvalid={validationErrors.name}
                                required
                            />
                            <Form.Control.Feedback type="invalid">{validationErrors.name}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} className="mb-3 " controlId="formGridEmail">
                            <Form.Label>
                                Email <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                name="email"
                                onChange={handleChange}
                                value={formData.email}
                                isInvalid={validationErrors.email}
                                required
                            />
                            <Form.Control.Feedback type="invalid">{validationErrors.email}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} className="mb-3 " controlId="formGridPhone">
                            <Form.Label>
                                Phone no.<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="tel"
                                pattern="[0-9]*"
                                maxLength="10"
                                minLength="10"
                                placeholder="Enter phone"
                                name="phone"
                                onChange={handleChange}
                                value={formData.phone}
                                isInvalid={validationErrors.phone}
                                required
                            />
                            <Form.Control.Feedback type="invalid">{validationErrors.phone}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} className="mb-3" controlId="formGridPurpose">
                            <Form.Label>
                                Purpose<span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Select
                                defaultValue="Choose..."
                                value={formData.purpose}
                                name="purpose"
                                onChange={handleChange}
                                className="purposeBox"
                                isInvalid={validationErrors.purpose}
                                required
                            >
                                <option disabled value="">
                                    Choose...
                                </option>
                                <option>Demo class</option>
                                <option>Registration</option>
                                <option>Enquiry</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{validationErrors.purpose}</Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} className="mb-3 " controlId="formGridCourse">
                            <Form.Label>
                                Course <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Select
                                defaultValue="Choose..."
                                value={formData.courseSelected}
                                name="courseSelected"
                                onChange={handleChange}
                                className="purposeBox"
                                isInvalid={validationErrors.courseSelected}
                                required
                            >
                                <option disabled value="">
                                    Choose...
                                </option>
                                <option>3D Modelling</option>
                                <option>3D Animation</option>
                                <option>UI/UX</option>
                                <option>Web Development</option>
                                <option>React.js</option>
                                <option>Node.Js</option>
                                <option>Web Development</option>
                                <option>Python</option>
                                <option>Java</option>
                                <option>C/C++</option>
                                <option>C#</option>
                                <option>Blockchain</option>
                                <option>Unreal</option>
                                <option>Unity</option>
                                <option>React Native</option>
                                <option>Machine Learning</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {validationErrors.courseSelected}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Row>
                            <Form.Group as={Col} className="mb-3 " controlId="formGridEmail">
                                <Form.Label>
                                    Location <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter location"
                                    name="location"
                                    onChange={handleChange}
                                    value={formData.location}
                                    isInvalid={validationErrors.location}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {validationErrors.location}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} className="mb-3 " controlId="formGridEmail">
                                <Form.Label>
                                    Education <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter education"
                                    name="education"
                                    onChange={handleChange}
                                    value={formData.education}
                                    isInvalid={validationErrors.education}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    {validationErrors.education}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    </Col>

                    <Button type="submit" className="formbtn w-100" variant="outline-dark">
                        Submit
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>
    );
};

export default EnquiryModal;
