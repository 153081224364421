import React from "react";
import "./Support.css";
import supp2 from "../../assets/courses_menu/supp-1.png";
import supp1 from "../../assets/courses_menu/supp-2.png";


const Support = () => {
  return (
    <div className="support">
      <div className="support-title">
        <p>INSTANT DOUBT SUPPORT</p>
      </div>
      <div className="support-content">
        <div className="support-left-side">
          <div className="support-img-left">
            <img src={supp1} alt="support" />
          </div>
          <div className="support-text-left">
            <h1 className="support-head">
              doubt solve <br /> 10-15 min
            </h1>
            <p className="support-about">
              Ask your doubts when you want,<br/> and get it solved within 10-15 <br/>
              minutes.
            </p>
          </div>
        </div>

        <div className="support-right-side">
          <div className="support-img-right">
            <img src={supp2} alt="support" />
          </div>

          <div className="support-text-right">
            <p className="support-head">personal doubts <br/> support</p>
            <p className="support-about">
              With robust Mentor Support,<br/> experience seamless learning with<br/>
              personal mentorship.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
