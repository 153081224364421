import React from "react";
import "./terms.css";
import Nav from "react-bootstrap/Nav";
import navvlogo from "../../assets/CodeTo Logo.png";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer";

const Terms = () => {
  const navigate = useNavigate();

  const homepage = () => {
    navigate("/");
  };
  return (
    <>
      <Helmet>
        <title>Codeto - Terms&Conditions </title>
        <meta name="description" content="termsAndCOnditions" />
      </Helmet>
      <Nav style={{ margin: "1.5rem" }}>
        <Nav.Item>
          <img src={navvlogo} id="terms" onClick={homepage} alt="noimg" />
        </Nav.Item>
      </Nav>
      <div className="terms">
        <h1 className="terms-title" >Terms And Conditions</h1>
        <p>
          Terms and conditions for a "CODETO" programming institute involves
          legally outlining the rights and responsibilities of both the
          institute and its students. While it's important to consult with a
          legal professional to ensure compliance with local laws and
          regulations,
          <br />
          <br />
          <h4 style={{color : "lightblue"}}>Terms and Conditions:</h4>
          <br />
          1. Admission and Registration
          <br />
          1.1. Admission to "CODETO" is subject to the availability of seats in
          the chosen program or course. <br />
          1.2. Students must complete the Enrollment process, including
          providing accurate and up-to-date information. <br />
          1.3. Admission may be denied or revoked for any misrepresentation of
          information during the registration process. <br /><br />
          2. Course Fees
          <br />
          2.1. Tuition fees must be paid in accordance with the payment schedule
          provided by "CODETO".
          <br />
          2.2. Non-payment of fees may result in suspension or termination from
          the program or course. <br />
          2.3. All fees are non-refundable unless explicitly stated in the
          institute's refund policy.
          <br /><br />
          3. Attendance and Punctuality
          <br />
          3.1. Students are expected to attend classes regularly and punctually.{" "}
          <br />
          3.2. "CODETO" reserves the right to withdraw students who consistently
          miss classes without valid reasons.
          <br /><br />
          4. Code of Conduct
          <br />
          4.1. Students are expected to conduct themselves with professionalism,
          courtesy, and respect for fellow students, instructors, and staff.
          <br /><br />
          5. Intellectual Property
          <br />
          5.1. All course materials, including but not limited to lectures,
          notes, and software, are the intellectual property of "CODETO" and may
          not be distributed or reproduced without permission.
          <br /><br />
          6. Refund Policy
          <br />
          6.1. "CODETO" may have a refund policy in place. Students are
          encouraged to review and understand this policy.
          <br /><br />
          7. Termination
          <br />
          7.1. "CODETO" reserves the right to terminate a student's enrollment
          for reasons including but not limited to non-payment, academic
          misconduct, or disruptive behavior.
          <br /><br />
          8. Privacy Policy
          <br />
          8.1. "CODETO" respects students' privacy and may collect and use
          personal information for educational purposes only. The institute's
          full privacy policy is available upon request.
          <br /><br />
          9. Changes to Terms and Conditions
          <br />
          9.1. "CODETO" reserves the right to amend or update these terms and
          conditions. Any changes will be communicated to students in advance.
          <br />
          By enrolling in "CODETO", students acknowledge that they have read,
          understood, and agreed to abide by these terms and conditions.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
