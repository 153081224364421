import React, { useState,useEffect} from 'react';
import './chatbotStyle.css';
import chatbotLogo from '../../assets/we_are_here.png';
import close from '../../assets/close-chat-btn.png'
import CustomChatbot from './Chatbot';

const ChatbotLogo = () => {
  const [showToggleableComponent, setShowToggleableComponent] = useState(false);

//open auto open chatbot after 3sec
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowToggleableComponent(true);
     
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, []);
  //....................................


  const handleButtonClick = () => {
    setShowToggleableComponent(!showToggleableComponent);
    
  };

  
  return (
    <div className="chatbot-logo-container" >
      {showToggleableComponent ? (
        <img src={close} alt="Close-Btn" className='chat-close' onClick={handleButtonClick} />
      ) : (
        <img src={chatbotLogo} alt="Chatbot Logo" className='chat-bot-logo loader' onClick={handleButtonClick} />
      )}
      <CustomChatbot showToggleableComponent={showToggleableComponent} />
    </div>
  );
};

export default ChatbotLogo;
