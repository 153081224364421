import React from "react";
import "./Features.css";
import fimg1 from "../../assets/courses_menu/fimg1.png";
import fimg2 from "../../assets/courses_menu/fimg2.png";
import fimg3 from "../../assets/courses_menu/fimg3.png";
import fimg4 from "../../assets/courses_menu/fimg4.png";

const Features = () => {
  return (
    <div className="features">
      <div className="features-title">
        <p>features</p>
      </div>

      <div className="features-content">
        <div className="features-left">
          <div className="fimg">
            <img src={fimg1} alt="features" />
            <img src={fimg2} alt="offlinbe" />
          </div>

          <div className="ftext">
            <h2>offline platform</h2>
          </div>
        </div>

        <div className="features-right">
          <div className="fimg">
            <img src={fimg3} alt="features" />
            <div className="fig4-container">
            <img className="fimg4" src={fimg4} alt="online" />
            </div>
           
          </div>

          <div className="ftext">
            <span>online platform</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
