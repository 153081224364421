import React from "react";
import "./Section1.css";
import image1 from "../../assets/game.png";
// import bubble1 from '../../assets/bubble.png'

const Section1 = () => {
  return (
    <div className="section1">
      <div className="what-is-codeto">
        <h1 className="what-codeto-title">what is codeto?</h1>
        {/* <img src={bubble1} className="bubble1" alt="no-img"/> */}
      </div>
      <div className="codeto-box-section">
        <div className="about-codeto-box">
          <p className="about">
          <ul>
            <li> CODETO is a learning program designed for aspiring developers who
            want to learn different courses.</li>
            <li>The program offers
            various features such as learning fundamentals, hands-on projects,
            personal mentorship, and Industrial certification.</li>
            <li>Experienced
            instructors of the program can help you unlock your development
            potential.</li>
            <li>You will experience office culture while learning.</li>
          </ul>
          </p>
        </div>
        <div className="about-codeto-container">
          <div className="about-codeto-image">
             <img src={image1} alt="what-is-codeto"/>
          </div>
        </div>
      </div>
     
    </div>
    
  );
};

export default Section1;
