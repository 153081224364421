import React, { useState } from "react";
import "./login.css";
import logo from "../../assets/logo2.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { adminLoginApi } from "../../baseUrl";
import toast from "react-hot-toast";

const Login = () => { 
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [email, setEmail] = useState("");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [password, setPassword] = useState(""); 
  const [isLoading, setIsLoading] = useState(false)
  // eslint-disable-next-line react-hooks/rules-of-hooks
// eslint-disable-next-line react-hooks/rules-of-hooks
const navigate = useNavigate();

const homepage = () => {
  navigate("/");
};

function handleSubmit(event) {
    event.preventDefault(); 
    setIsLoading(true)
    const data = {
      email: email,
      password: password
    };

    axios.post(adminLoginApi, data)
      .then(response => {
        const result = response.data;  
        console.log( result.responseResult._id);
        localStorage.setItem("Result", result);
        if (result.success) {
          localStorage.setItem("userID", result.responseResult._id);
          navigate('/Show');
          setIsLoading(false)
          toast.success("Login Successful")
        } else {
              toast.error("wrong credentials")
              setIsLoading(false)
        }
      })
      .catch(error => {
        console.log(error)
        toast.error("Login failed, enter valid credentials")
        setIsLoading(false)
      });
      setIsLoading(false)
  } 
 
  return (
    <>
      <section id="section" className="vh-100">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div  onClick={homepage} className="col-md-9 col-lg-6 col-xl-5">
              <img src={logo} className="img-fluid" alt="noimg" />
            </div>
            <div id="form" className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
              <form className="adminform">
                  <h4 className="text-center my-4">Admin Login</h4>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1"  >Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    onChange={(e)=>{setEmail(e.target.value)}}
                  />
                </div>
                <br />
                <div className="form-group">
                  <label htmlFor="exampleInputPassword1" >Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Password"
                    onChange={(e)=>{setPassword(e.target.value)}}
                  />
                </div>

                <button type="submit" id="admin-btn" className="btn btn-primary" onClick={handleSubmit}>
                 {isLoading ? 'Logging in...' : 'Login'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
