import React from "react";
import "./Designing.css";
import uiux from "../../../assets/courses_badge/uiux-img.png";
import animation from "../../../assets/courses_badge/3d-animation-img.png";
import modelling from "../../../assets/courses_badge/3d-modelling-img.png";
import { HashLink as Link } from "react-router-hash-link";

const Designing = () => {
  return (

    <>

    <div className="section2-designing">
      <Link to="/Coursespage/15#main">
        <div className="tab-desinging-content">
          <img src={uiux} alt="no-img" />
        </div>
      </Link>

     <Link to="/Coursespage/1#main">
      <div className="tab-desinging-content">
          <img src={animation} alt="no-img" />
        </div>
     </Link>

     <Link to="/Coursespage/4#main">
      <div className="tab-desinging-content">
          <img src={modelling} alt="no-img" />
        </div>
     </Link>
    
   
     
    </div>
    <div className="showmore-line2" style={{margin: "auto"}}></div>
    </>

  );
};

export default Designing;
