import React from 'react'

const Stars = () => {
  return (
    <div style={{display:"flex", color:"rgba(255, 212, 38, 1)", width: "100%",}} className="stars">
    <div>
    <i class="fa-solid fa-star" >&nbsp;&nbsp;</i>
    <i class="fa-solid fa-star" >&nbsp;&nbsp;</i>
    <i class="fa-solid fa-star" >&nbsp;&nbsp;</i>
    <i class="fa-solid fa-star" >&nbsp;&nbsp;</i>
    <i class="fa-solid fa-star" >&nbsp;&nbsp;</i>
    </div>
      
    </div>
  )
}

export default Stars;
