import React, { useEffect } from "react";
import "./Footer.css";
import logo from "../../assets/CodeTo Logo.png";
import { HashLink as Link } from "react-router-hash-link";
import AOS from 'aos';
import 'aos/dist/aos.css';
//icons
import phone from "../../assets/FooterLogo/telephone.png";
import mail from "../../assets/FooterLogo/gmail.png";
import location from "../../assets/FooterLogo/location.png";
import twitter from '../../assets/FooterLogo/twitter.png'
import instagram from "../../assets/FooterLogo/instagram.png";
import whatsapp from "../../assets/FooterLogo/whatsapp.png";
import youtube from "../../assets/FooterLogo/youtube.png";
import linkdin from "../../assets/FooterLogo/linkedin.png";

const Footer = () => {


  useEffect(() => {
    AOS.init({
      duration: 2000, // Adjust the animation duration as needed
      once: false, // Only animate elements once
    });
  }, []);



  return (
    <div className="footer">
      <div className="footer-logo">
        <img src={logo} alt="codeto" />
      </div>
      <div className="footer-text">
        <p>
        “Develop a passion for learning. If you do, you will never cease to grow.”
        </p>
      </div>

      <div className="full" >
        <div className="contact-section" >
          {/* <div className="row gap-md-0 gap-3 mb-4"> */}
          <div className="mobile col-md-4 gap-4" >
            <div className="">
              <a href="tel:7303308574">
                <img src={phone} alt="phone" />
              </a>
            </div>

            <span>7303308574</span>
          </div>
          <div className="weblink col-md-4 gap-2">
            <div className="">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=codeto.info@gmail.com"
              >
                <img src={mail} alt="gmail" />
              </a>
            </div>

            <span>&nbsp; codeto.info@gmail.com</span>
          </div>
          <div className="address col-md-4 gap-2">
            <div className="">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://goo.gl/maps/YeMiMNLFb7RNkdVZ9"
              >
                <img src={location} alt="googlemap" />
                {/* <i class="fa-solid fa-envelope" /> */}
              </a>
            </div>

            <span className="add">
              &nbsp; B-block, 18/4, 4th floor, opposite Jhilmil Metro Station
              Gate no.1, Delhi 110095
            </span>
          </div>
        </div>

        <div className="social-media mb-4" data-aos="zoom-in-down">
          {/* <div className="twitter">
          <a
            rel="noreferrer"
            target="_blank"
            href="https://twitter.com/Abhiwan_Codeto/"
          >
            {" "}
            <img src={twitter} alt="phone" />
          </a>
        </div> */}

          <div>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.instagram.com/codetolearning/"
            >
              <img src={instagram} alt="instagram" />
            </a>
          </div>

          <div>
            <a
              rel="noreferrer"
              href="https://www.linkedin.com/company/codeto-abhiwantechnology/?viewAsMember=true"
              target="_blank"
            >
              <img src={linkdin} alt="linkdin" />
            </a>
          </div>

          <div>
            <a
              rel="noreferrer"
              href="https://wa.me/message/ZM2AWWQPYHE2M1"
              target="_blank"
            >
              <img src={whatsapp} alt="whatsapp" />
            </a>
          </div>

          <div>
            <a
              rel="noreferrer"
              href="https://www.youtube.com/@CodeToLearning/about"
              target="_blank"
            >
              <img src={youtube} alt="youtube" />
            </a>
          </div>

          <div>
            <a
              rel="noopener noreferrer"
              href="https://twitter.com/Codeto805"
              target="_blank"
            >
              <img src={twitter} alt="youtube" />
            </a>
          </div>
        </div>
      </div>

      <div className="footer-end">
        <p>©2024 Proudly Created by Abhiwan Technology</p>&nbsp; &nbsp;| &nbsp;
        &nbsp;
        <div className="privacy-footer">
          <Link to="/privacy-policy#privacy" style={{ textDecoration: "none" }}>
            {" "}
            <p>Privacy policy</p>
          </Link>
        </div>
        &nbsp; &nbsp; | &nbsp; &nbsp;
        <div className="terms-footer">
          <Link to="/terms-conditions#terms" style={{ textDecoration: "none" }}>
            <p>Terms & Conditions</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
