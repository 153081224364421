import React, {useState} from "react";
import "./Navbar2.css";
import EnquiryModal from '../../EnquiryForm/Modal'
import logo from "../../assets/courses_menu/CoursePage_logo.png";
import { HashLink as Link } from "react-router-hash-link";

import Sidebar from "../../components/Sidebar/Sidebar";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {

  //  const [showNavItems, setShowNavItems] = useState(false);

  // const toggleMenu = () => {
  //   setShowNavItems((prevShowNavItems) => !prevShowNavItems);
  // };

  

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };


  const [showNavItems, setShowNavItems] = useState(false);
  const [showCourseDropdown, setShowCourseDropdown] = useState(false);

  const toggleCourseDropdown = () => {
    setShowCourseDropdown(!showCourseDropdown);
  };

  const notToggleCourseDropdown = () => {
    setShowCourseDropdown(false);
  };
  // eslint-disable-next-line no-unused-vars
  const navOpen = () => {
    setShowNavItems(true);
  };

  const navClose = () => {
    setShowNavItems(false);
  };

  
  const toggleMenu = () => {
    setShowNavItems(!showNavItems);
   
  };

  return (
    <nav className="navbar2">
      <div>
         <Link to="/">
          <img className="nav-logo" src={logo} alt="navbar" />
        </Link>
        <h6 className="tagline-logo" style={{color: "black"}}>
            Ignite Your Potential, Inspire Your Future.
          </h6>
      </div>
      <div className='nav2-items'>
        <Link to="/">
           HOME  &nbsp;
        </Link>
       
        <div  className="dropdown" onMouseEnter={toggleCourseDropdown} onMouseLeave={notToggleCourseDropdown}>
        <button  style={{color: "purple"}} className=" Nav2-items"  onClick={toggleCourseDropdown}>
       COURSE
        </button>
        {showCourseDropdown && (
          <div className="dropdown-content">

        <div>    <Link to='/Coursespage/15#main'>◦ UI/UX</Link>
            <Link to='/Coursespage/1#main'>◦ 3D Animation</Link>
            <Link to='/Coursespage/4#main'>◦ 3D Modelling</Link>
            <Link to='/Coursespage/11#main'>◦ React.Js</Link>
            <Link to='/Coursespage/2#main'>◦ Node.Js</Link>
            <Link to='/Coursespage/14#main'>◦ Full Stack</Link>
            <Link to='/Coursespage/9#main'>◦ Web Development</Link>
            <Link to='/Coursespage/8#main'>◦Machine Learning</Link>
            </div>
            <div>  <Link to='/Coursespage/3#main'>◦ React Native</Link>
            <Link to='/Coursespage/13#main'>◦ Unity</Link>
            <Link to='/Coursespage/12#main'>◦ Unreal</Link>
            <Link to='/Coursespage/5#main'>◦ C#</Link>
            <Link to='/Coursespage/16#main'>◦ C/C++</Link>
            <Link to='/Coursespage/7#main'>◦ Python</Link>
            <Link to='/Coursespage/6#main'>◦ Java</Link>
            <Link to='/Coursespage/10#main'>◦ BlockChain</Link></div>
          
            
          </div>
        )}
      </div>
        <Link to="/#culture"> CULTURE</Link>
        <Link to="/#aboutus" style={{marginLeft:"-.7rem"}}> ABOUT US</Link>
      </div>
      <div>
        <button className="nav-button2" onClick={openModal}>ENQUIRY</button>
      </div>

      <div className="hamburger-icon" onClick={toggleMenu}>
          {showNavItems ? (
            <FaTimes style={{ color: "black", fontSize: "1.8rem" }} />
          ) : (
            <FaBars style={{ color: "black", fontSize: "1.8rem" }} />
          )}
        </div>
        <Sidebar isOpen={showNavItems} onClose={navClose} />
      <EnquiryModal isOpen={modalIsOpen} onClose={closeModal} />
    </nav>
  );
};

export default Navbar;
