import React from 'react'

const NoPage = () => {
  return (
    <div className='d-flex justify-content-center align-items-center' style={{height: '100dvh'}}>
        <h1 className='text-light'>No page found </h1>
    </div>
  )
}

export default NoPage