import React, { useState } from "react";
import "./Technical.css";
import blockchain from "../../../assets/courses_badge/Blockchain.png";
import csharp from "../../../assets/courses_badge/csharp.png";
import nodejs from "../../../assets/courses_badge/nodejs.png";
import reactnative from "../../../assets/courses_badge/reactnative.png";
import java from "../../../assets/courses_badge/java.png";
import unreal from "../../../assets/courses_badge/unreal.png";


import python from "../../../assets/courses_badge/python.png";
import c from "../../../assets/courses_badge/c.png";
import web_dev from "../../../assets/courses_badge/web_dev.png";
import reactjs from "../../../assets/courses_badge/reactjs.png";
import ml from "../../../assets/courses_badge/ml.png";
import unity from "../../../assets/courses_badge/unity.png";
import fullstack from "../../../assets/courses_badge/full_stack.png";
// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";


const Technical = () => {
  const [more, setMore] = useState(false);


  const toggleMenu = () => {
    setMore((prevState) => !prevState);
  };
  return (
    <div>
      <div className="teachnical-tab-content">
        <div className="course-grid" style={{height : more ? 'auto' : 'auto'}}>
          <Link to="/Coursespage/10#main">
            <div className="course-item">
              <img src={blockchain} alt="no-img" />
            </div>
          </Link>

       
          <Link to="/Coursespage/12#main">
            <div className="course-item">
              <img src={unreal} alt="no-img" />
            </div>
          </Link>

          <Link to="/Coursespage/6#main">
             <div className="course-item">
               <img src={java} alt="no-img" />
             </div>
          </Link>


         <Link to="/Coursespage/3#main">
         <div className="course-item">
            <img src={reactnative} alt="n" />
          </div>
         </Link>

         <Link to="/Coursespage/2#main">
          <div className="course-item">
              <img src={nodejs} alt="nodejs-course" />
            </div>
         </Link>

         
        <Link to="/Coursespage/5#main">
          <div className="course-item">
            <img src={csharp} alt="csharp-course" />
          </div>
        </Link>

          {/* more section */}
          {more && (
            <>
              <Link to="/Coursespage/7#main">
                <div className="course-item">
                  <img src={python} alt="python-course" />
                </div>
              </Link>
               
              <Link to="/Coursespage/16#main">
              <div className="course-item">
                <img src={c} alt="c-course" />
              </div>
              </Link>
           

              <Link to="/Coursespage/9#main">
              <div className="course-item">
                <img src={web_dev} alt="web-development-course" />
              </div>
              </Link>

              <Link to="/Coursespage/13#main">
              <div className="course-item">
                <img src={unity} alt="unity-course" />
              </div>
              </Link>
              
              <Link to="/Coursespage/8#main">
              <div className="course-item">
                <img src={ml} alt="machine-learning-course" />
              </div>
              </Link>
             
              <Link to="/Coursespage/11#main">
              <div className="course-item">
                <img src={reactjs} alt="react-js-courses" />
              </div>
              </Link>

              <Link to="/Coursespage/14#main">
              <div className="course-item">
                <img src={fullstack} alt="fullstack-course" />
              </div>
              </Link>
            </>
          )}
        </div>
        <div className="show-more-btn">
            <button className="nav-button" onClick={toggleMenu}>
              {more ? "show less" : "show more "}{" "}
              {more ?  (
                <i class="fa-solid fa-circle-chevron-up"></i>
              ) : (
                <i class="fa-solid fa-circle-chevron-down"></i>
              )}
            </button>
          </div>
          <div className="showmore-line"></div>
      </div>
    </div>
  );
};

export default Technical;
