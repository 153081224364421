import React from "react";

const Polygon = (props) => {
  return (
    <div className="polygon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="299"
        height="77"
        viewBox="0 0 299 77"
        fill="none"
      >
        <path
          d="M6 2.77344H249.019L293.925 38.2856L249.019 73.7977H6L32.5809 38.2856L6 2.77344Z"
          fill={props.color}
          stroke="white"
          stroke-width="5.10354"
        />
      </svg>
    </div>
  );
};

export default Polygon;
