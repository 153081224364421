import React, { useState,  useEffect } from "react";
import "./Course.css";
import EnquiryModal from '../../EnquiryForm/Modal'
import duration_image from "../../assets/courses_menu/duration.png";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

function Course({getSingleCourse, singleTechCourse}) {
 const {id} = useParams();

   useEffect(() => {
    getSingleCourse(id);
   // eslint-disable-next-line react-hooks/exhaustive-deps
   },[id]);
 console.log("sinsingleTechCoursegleC",singleTechCourse);


  //modal enquiry
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };


  return (
    <div className="single_course" id="main" >
        <Helmet>
        <title> Course - {`${singleTechCourse[0]?.title}`}</title>
        <meta name="description" content="Courses" />
      </Helmet>
      <div className="imagecourse">
        <img src={singleTechCourse[0]?.img} from alt="courses" />
      </div>

      <div className="right-side">
        <div className="text">
          <p className="main_title">{singleTechCourse[0]?.title}</p>
          <p className="info">
           {
             singleTechCourse[0]?.subtitle
           }
          </p>
        </div>

        <div className="duration">
          <div className="duration-img">
            <img src={duration_image} alt="courses" />
          </div>
          <div className="duration-text">
            <p>Course duration - 3 months</p>
          </div>
        </div>

        <div >
          <button className="democlass-btn" onClick={openModal}>Demo class</button>
        </div>
        <EnquiryModal isOpen={modalIsOpen} onClose={closeModal} />
      </div>
    </div>
  );
}

export default Course;

