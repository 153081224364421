import React from "react";
import "./Section2.css";
import Designing from "./Designing/Designing";
// import Technical from "./Technical/Technical";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Technical from "./Technical/Technical";

const Section2 = () => {
  return (
    <div className="courses" id="course">
      <div className="courses-head">
        <h1 className="main-title">course</h1>
        <h4 className="child-title">courses we provide </h4>
      </div>

    <Tabs
      defaultActiveKey="Designing"
      id="fill-tab-example"
      className="mb-3 formTab"
      fill
    >
      <Tab eventKey="Designing" title="Designing">
      <Designing/>
      </Tab>
      <Tab eventKey="Technical" title="Technical">
      <Technical/>
      </Tab>
    
    </Tabs>
    </div>
  );
};

export default Section2;
