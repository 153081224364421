/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Nav from "react-bootstrap/Nav";
import "./Show.css";
import navvlogo from "../../assets/CodeTo Logo.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getUserListApi, updateContactStatusApi } from "../../baseUrl";
import toast from "react-hot-toast";

const Show = () => {
  const [userData, setUserData] = useState([]);
  const [options, setOption] = useState([

     {
       option : 'Pending',
       select : true
     },
     {
      option : 'Connected',
      select : false
    },
  ])
  const navigate = useNavigate();

  const homepage = () => {
    navigate("/");
  };

  const handleLogout = () => {
    localStorage.removeItem("userID");
    navigate("/admin");
  };

  //API TO GT REGESTIRED USERS LIST
  useEffect(() => {
    axios
      .get(getUserListApi)
      .then((response) => {
        const result = response.data.responseResult;
        console.log("result", result);
        setUserData(result?.reverse());
      })
      .catch((error) => {
        console.log(error);
        toast.error('Server error')
      });
  }, []);


  const handleChange = (userId,e) => {
     console.log(e.target.value);
     if(e.target.value === "Pending") {
      updateStatus(userId,false);
     } else {
      updateStatus(userId,true);
     }
  }
 
  function updateStatus(userId,status) {
    console.log(userId,status);
    axios
    .post(updateContactStatusApi, {
      userId,
      contacted : status
    })
    .then((response) => {

      toast.success('user status updated!')
    })
    .catch((error) => {
      console.log(error);
    });
  }
 

  return (
    <div className="admin-container " >
      <Nav style={{ margin: "2rem" }}>
        <Nav.Item>
          <img src={navvlogo} onClick={homepage} alt="noimg" />
        </Nav.Item>
      </Nav>
      <Nav
        className="justify-content-end"
        style={{ margin: " -4rem 2rem", color: "white" }}
      >
        <Nav.Item>
          <Nav.Link >
            <div className="adminname"><i className="fa-solid fa-circle" style={{ color: "#51ec5b" }}></i>{" "}
            admin</div>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link className="logout" onClick={handleLogout}>
            Logout
          </Nav.Link>
        </Nav.Item>
      </Nav>

      <Table className="table" striped bordered hover variant="dark" responsive="md">

        <thead>
          <tr>
            <th scope="col">S.no</th>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone</th>
            <th scope="col">Purpose</th>
            <th scope="col">Course</th>
            <th scope="col">Location</th>
            <th scope="col">Education</th>
            <th scope="col">Contacted Status</th>
          </tr>
        </thead>
        
        <tbody>
          {userData.map((user, index) => (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>{user.name}</td>
              <td style={{ height: "50px" }}>{user.email}</td>
              <td>{user.phone}</td>
              <td>{user.purpose}</td>
             
              <td>{user.courseSelected}</td>
              <td>{user.location}</td>
              <td>{user.education}</td>
              <td>
                <select
                  name="status"
                  onChange={(e) => handleChange(user._id,e)}
                >
                   {
                    options.map(op => {
                       return <option value={op.option} selected={user.contacted}>{op.option}</option>
                    })
                   }
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Show;