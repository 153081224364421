import React from "react";
import "./Roadmap.css";
import Polygon from "./Polygon";
// import VerticalLineLine from "../CoursesHighlight/VerticalLine";
import Circle from "./Circle";

import map1 from '../../assets/Roadmap/map1.png'
import map2 from '../../assets/Roadmap/map2.png'
import map3 from '../../assets/Roadmap/map3.png'
import map4 from '../../assets/Roadmap/map4.png'
import map5 from '../../assets/Roadmap/map5.png'

const Roadmap = ({ color, imgg }) => {
  return (
    <div className="roadmap">
      <div className="roadmap-title">
        <span>CODETO ROADMAP</span>
      </div>
    <div className="roadmap-scale">
      <div className="roadmap-container">
      <div className="polygon">
        <div>
          <p>
            Pick a course from
            <br /> "CODETO".
          </p>
          <Polygon color="#FFBE00" />
          <Circle imgg={map1}/>
        </div>
        <div>
          <p>
            Improve Your <br />
            Technical Skill.
          </p>{" "}
          <Polygon color="#187A99" />
          <Circle  imgg={map2}/>
        </div>
        <div>
          <p>
            Build your
            <br /> Resume.
          </p>
          <Polygon color="#FFBE00" />
          <Circle imgg={map3}/>
        </div>
        <div>
          <p>
            Get Certified in your <br />
            Professional career.
          </p>{" "}
          <Polygon color="#187A99" />
          <Circle imgg={map4}/>
        </div>
        <div>
          <p>
            Internship/Job <br />
            Assistance.
          </p>
          <Polygon color="#FFBE00" />
          <Circle imgg={map5}/>
        </div>
      </div>
      </div>

    </div>
    
         </div>
  );
};

export default Roadmap;

