import React, { useEffect } from "react";
import Header2 from "../CoursesMenu/Header2/Header2.js";
import Highlight from "../CoursesMenu/CoursesHighlight/Highlight";
import Course from "../CoursesMenu/IndividualCourse/Course";
import Achievements from "../CoursesMenu/Achievements/Achievements";
import Criteria from "../CoursesMenu/Criteria/Criteria";
import Support from "../CoursesMenu/DoubtSupport/Support.js";
import Features from "../CoursesMenu/Features/Features.js";
import Roadmap from "../CoursesMenu/Roadmap/Roadmap.js";
import Interested from "../CoursesMenu/Interested/Interested.js";
// import Rating from "../CoursesMenu/Rating/Rating.js";
import Footer from "../components/Footer/Footer.js";
import ChatbotLogo from "../components/Chatbot/ChatbotLogo.js";
import RatingCarousel from "../RatingNew/RatingCarousel.js";


import AOS from 'aos';
import 'aos/dist/aos.css';

const Coursespage = ({ getSingleCourse, singleTechCourse }) => {

  
    useEffect(() => {
      AOS.init({
        duration: 1000, // Adjust the animation duration as needed
        once: false, // Only animate elements once
      });
    }, []);


  return (
    <div>
       <div data-aos="fade-up"> <Header2 /></div>
       <div data-aos="fade-up">  <Highlight /></div>
       <div> <Course
        getSingleCourse={getSingleCourse}
        singleTechCourse={singleTechCourse}
      /></div>
     
     <div data-aos="fade-up"> <Achievements /></div>
       <div data-aos="fade-up">  <Criteria /> </div>
       <div data-aos="fade-up">  <Support /></div>
       <div data-aos="fade-up">   <Features /> </div>
       <div data-aos="fade-up">  <Roadmap /></div>
       <div data-aos="fade-up">   <Interested  getSingleCourse={getSingleCourse}
        singleTechCourse={singleTechCourse}/></div>
         <div data-aos="fade-up"> <RatingCarousel/></div>
      
      <Footer/>
      <ChatbotLogo/>
    </div>
  );
};

export default Coursespage;
