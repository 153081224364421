import React from "react";
import "./Section5.css";
import aboutimage from "../../assets/logo2.png";

const Section5 = () => {
  return (
    <div className="about-us" id="aboutus">
      <div className="about-us-title">
        <h1>ABOUT US</h1>
      </div>
      <div className="codeto-box-section">
        <div className="about-us-box">
          <p className="about-codeto">
          <a rel="noreferrer" href="https://www.abhiwan.com/" target="_blank" style={{textDecoration: 'none'}}>Abhiwan Technology</a>, a game development agency that works on
            developing games and new age technologies, started planning a
            learning course for students “CODETO” from Jan 2023 and launched its
            first batch on 14 Feb 2023. It is an Expert Learning Program that is
            designed to provide students with the necessary skills and
            knowledge. CodeTo team of experienced instructors will provide
            students with personalised attention and guidance throughout the
            course, ensuring that each student receives the support they need to
            achieve their career goals. 
          </p>
        </div>
        <div className="codeto-image-bg">
          <div className="codeto-image-image">
            <img src={aboutimage} alt="aboutus" width="100%"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
