import React, { useState } from "react";
import EnquiryModal from "../../EnquiryForm/Modal";
import "./Navbar.css";
import logo from "../../assets/CodeTo Logo.png";
import Sidebar from "../Sidebar/Sidebar";
import { HashLink as Link } from "react-router-hash-link";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
  const [showNavItems, setShowNavItems] = useState(false);
  const [activeNavItem, setActiveNavItem] = useState(0);

  const toggleMenu = () => {
    setShowNavItems(!showNavItems);
    setActiveNavItem(0);
  };

  const handleNavItemClick = (index) => {
    setActiveNavItem(index);
    setShowNavItems(false);
  };

  //modal enquiry
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  // eslint-disable-next-line no-unused-vars
  const navOpen = () => {
    setShowNavItems(true);
  };

  const navClose = () => {
    setShowNavItems(false);
  };

  //Dropdown
  const [showCourseDropdown, setShowCourseDropdown] = useState(false);

  const toggleCourseDropdown = () => {
    setShowCourseDropdown(!showCourseDropdown);
  };

  const notToggleCourseDropdown = () => {
    setShowCourseDropdown(false);
  };

  return (
    <nav className="navbar">
      <div>
        <Link to="/" style={{ textDecorartion: "none !important" }}>
          <img className="nav-logo" src={logo} alt="codeto-logo" />
        </Link>
        <h6 className="tagline-logo">
            Ignite Your Potential, Inspire Your Future.
          </h6>
      </div>
      <div className="nav-items ">
        <a
          className={`nav-link ${activeNavItem === 0 ? "active" : ""}`}
          href="/"
          onClick={() => handleNavItemClick(0)}
        >
          HOME
        </a>
        <div
          className="dropdown mobile-dropdown"
          onMouseEnter={toggleCourseDropdown}
          onMouseLeave={notToggleCourseDropdown}
        >
          <button className="">COURSE</button>
          {showCourseDropdown && (
            <div className="dropdown-content">
              <div>
                <Link to="/Coursespage/15#main">◦ UI/UX</Link>
                <Link to="/Coursespage/1#main">◦ 3D Animation</Link>
                <Link to="/Coursespage/4#main">◦ 3D Modelling</Link>
                <Link to="/Coursespage/11#main">◦ React.Js</Link>
                <Link to="/Coursespage/2#main">◦ Node.Js</Link>
                <Link to="/Coursespage/14#main">◦ Full Stack</Link>
                <Link to="/Coursespage/9#main">◦ Web Development</Link>
                <Link to="/Coursespage/8#main">◦Machine Learning</Link>
              </div>
              <div>
                <Link to="/Coursespage/3#main">◦ React Native</Link>
                <Link to="/Coursespage/13#main">◦ Unity</Link>
                <Link to="/Coursespage/12#main">◦ Unreal</Link>
                <Link to="/Coursespage/5#main">◦ C#</Link>
                <Link to="/Coursespage/16#main">◦ C/C++</Link>
                <Link to="/Coursespage/7#main">◦ Python</Link>
                <Link to="/Coursespage/6#main">◦ Java</Link>
                <Link to="/Coursespage/10#main">◦BlockChain</Link>
              </div>
            </div>
          )}
        </div>
        <a
          className={`nav-link ${activeNavItem === 2 ? "active" : ""}`}
          href="#culture"
          onClick={() => handleNavItemClick(2)}
        >
          CULTURE
        </a>
        <a
          className={`nav-link ${activeNavItem === 3 ? "active" : ""}`}
          href="#aboutus"
          onClick={() => handleNavItemClick(3)}
          style={{ marginLeft: "-.7rem" }}
        >
          ABOUT US
        </a>
      </div>

      <div className="nav-buttons">
        <div>
          <button className="nav-button" onClick={openModal}>
            ENQUIRY
          </button>
        </div>

        <div className="hamburger-icon" onClick={toggleMenu}>
          {showNavItems ? (
            <FaTimes style={{ color: "#fff", fontSize: "1.8rem" }} />
          ) : (
            <FaBars style={{ color: "#fff", fontSize: "1.8rem" }} />
          )}
        </div>
        <Sidebar isOpen={showNavItems} onClose={navClose} />
      </div>
      <EnquiryModal isOpen={modalIsOpen} onClose={closeModal} />
    </nav>
  );
};

export default Navbar;
